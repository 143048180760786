export default {
  login: "ĐĂNG NHẬP",
  logout: "Thoát",
  password: "Mật khẩu",
  forgotPassword: "Quên mật khẩu",
  changePassword: "Đổi mật khẩu",
  changePasswordSuccessTitle: "Đổi mật khẩu thành công",
  changePasswordSuccessContent: "Mật khẩu của bạn đã được đổi thành công.",
  currentPassword: "Mật khẩu hiện tại",
  pleaseInputCurrentPassword: "Vui lòng nhập mật khẩu cũ của bạn",
  newPassword: "Mật khẩu mới",
  pleaseInputNewPassword: "Vui lòng nhập mật khẩu mới của bạn",
  passwordErrorMinimum: "Mật khẩu phải có ít nhất 8 kí tự.",
  passwordErrorUppercase: "Mật khẩu phải có ít nhất 1 kí tự in hoa.",
  passwordErrorLowercase: "Mật khẩu phải có ít nhất 1 thường.",
  passwordErrorNumber: "Mật khẩu phải có ít nhất 1 chữ số.",
  slogan: "Nhận dạng giọng nói tự động",
  companyRights: "© 2022 NAMI TECHNOLOGY JSC, Inc. All rights reserved.",
  dictionary: "Từ điển",
  dictionarySuccess: "Từ điển đã được lưu thành công",
  word1: "Từ ngữ sai",
  word2: "Incorrect yomigana",
  word3: "Từ ngữ đúng",
  word4: "Correct yomigana",
  word5: "Loại",
  transcripts: "Bản dịch",
  transcript: "Bản dịch",
  correctedTranscript: "Bản dịch đã sửa",
  createdAt: "Thời gian tạo",
  audio: "Âm thanh",
  secLimit: "Giới hạn 600 giây",
  seconds: "giây",
  predicting: "Đang dự đoán...",
  selectDragDropAudio: "Chọn hoặc kéo thả file âm thanh",
  selectDragDropVideo: "Chọn hoặc kéo thả file ghi âm/ghi hình",
  bugSuccess: "Lỗi đã được tạo thành công",
  selectLanguage: "Chọn ngôn ngữ",
  japanese: "Tiếng Nhật",
  vietnamese: "Tiếng Việt",
  haveBugs: "Có lỗi",
  noMessage: "Bạn chưa có tin nhắn!",
  placeholderPassword: "Mật khẩu",
  loading: "Đang tải",
  user: "Người dùng",
  admin: "Admin",
  doYouWantToDeleteTheseItems: "Bạn muốn xóa các mục này?",
  doYouWantToCancel: "Bạn có chắc muốn thoát?",
  btnSubmit: "Gửi đi",
  btnStop: "Dừng",
  requiredPassword: "Mật khẩu bắt buộc có",
  btnCancel: "Hủy",
  btnUpdate: "Cập nhật",
  btnAdd: "Thêm",
  btnEdit: "Sửa",
  btnDelete: "Xóa",
  btnSave: "Lưu",
  btnRecord: "Ghi âm",
  requiredEmail: "Email bắt buộc có",
  email: "Email",
  emailRequirePlaceholder: "Vui lòng điền email",
  recordAudio: "Âm thanh ghi âm",
  name: "Tên",
  phone: "Điện thoại",
  active: "Hoạt động",
  inactive: "Không hoạt động",
  word1Required: "Từ ngữ sai bắt buộc có",
  word2Required: "Incorrect yomigana is required",
  word3Required: "Từ ngữ đúng bắt buộc có",
  word4Required: "Correct yomigana is required",
  word5Required: "Loại bắt buộc có",
  connectionStatus: "Tình trạng kết nối",
  closed: "Đã đóng",
  connecting: "Đang kết nối",
  connected: "Đã kết nối",
  live: "Trực tiếp",
  offline: "Gián tiếp",
  btnStart: "Bắt đầu",
  successSave: "Lưu thành công",
  btnDownload: "Tải xuống",
  btnUpload: "Tải lên",
  line: "dòng",
  uploadFail: "tải lên thất bại!",
  uploadSuccess: "dòng đã được tải lên thành công!",
  meetings: "Các cuộc họp",
  btnView: "Xem",
  actions: "Thao tác",
  meeting: "",
  export: "Xuất file",
  exportAll: "Xuất JSON",
  exportSelected: "Xuất các mục đã chọn",
  revert: "Hoàn lại ban đầu",
  play: "Phát",
  pause: "Tạm dừng",
  jumpTo: "Nhảy đến",
  jumpToTimestamp: "Nhảy đến thời điểm",
  speed: "Tốc độ",
  volume: "Âm lượng",
  setVolume: "Đặt âm lượng",
  notes: "Ghi chú",
  writeNotesHere: "Viết ghi chú ở đây",
  participants: "Người tham dự",
  length: "Độ dài",
  date: "Thời gian họp",
  ID: "ID cuộc họp",
  lastEdit: "Cập nhật",
  sortBy: "Thứ tự",
  sortByRequired: "Order is required",
  inProgress: "Đang diễn ra...",
  filter: "Lọc",
  showAll: "Hiển thị tất cả",
  showOnlySelected: "Chỉ hiển thị những dòng được chọn",
  dev: "Dev",
  transcriptionStarted: "Bắt đầu ghi",
  transcriptionStopped: "Dừng ghi",
  replacedDict: "Đã thay thế với từ điển",
  hour: "Giờ",
  minute: "Phút",
  second: "Giây",
  apply: "Áp dụng",
  jumpToNewTimestampSuccess: "Chuyển đến thời gian mới thành công",
  jumpToEndSuccess: "Đã chuyển đến cuối",
  saveSuccess: "Lưu thành công",
  saveFail: "Lưu thất bại",
  uploadDate: "Ngày tải lên",
  status: "Trạng thái",
  pasteVideo: "Hoặc dán đường dẫn link video tại đây",
  downloading: "Đang tải xuống",
  uploading: "Đang tải lên server",
  transcribing: "Đang xử lý",
  transcribed: "Đã xử lý",
  record: "Ghi âm",
  ready: "Đã xử lý",
  action: "Thao tác",
  success: "Thành công",
  error: "Có lỗi",
  fileTranscriptSettings:
    "Hãy chọn số người nói và setting file ghi âm / ghi hình của bạn:",
  onePerson: "1 người nói",
  twoPeopleMonoChannel: "2 người nói, file không tách kênh",
  twoPeopleStereoChannels: "2 người nói, file có tách riêng kênh mỗi người",
  twoPeopleMonoChannelWithoutOverlap:
    "2 người nói không tách kênh và 2 người không nói chồng lên nhau",
  twoPeopleOrMore: "2 people or more",
  person: "Người",
  people: "Người",
  statistics: "Thống kê",
  daily: "Theo ngày",
  monthly: "Theo tháng",
  search: "Tìm kiếm",
  today: "Hôm nay",
  yesterday: "Hôm qua",
  thisWeek: "Tuần này",
  thisMonth: "Tháng này",
  thisQuarter: "Quý này",
  thisYear: "Năm này",
  total: "Tổng thời gian",
  minutes: "phút",
  hours: "tiếng",
  totalCost: "Tổng giá tiền",
  otherDict: "KHÁC",
  organizationDict: "TÊN TỔ CHỨC",
  personDict: "TÊN NGƯỜI",
  locationDict: "ĐỊA DANH",
  wrongEmailPassword: "Sai email hoặc mật khẩu",
  wrongEmailFormat: "Email sai định dạng",
  resetPasswordSuccess:
    "Đường dẫn thay đổi mật khẩu đã được gửi đến email của bạn",
  userNotFound: "Không tìm thấy người dùng",
  backToLogin: "Quay về đăng nhập",
  meetingClose: "Kết thúc cuộc họp",
  deleteSuccess: "Xóa thành công",
  deleteFail: "Xóa thất bại",
  uploaded: "Đã tải lên",
  callDate: "Thời điểm cuộc gọi",
  pickUp: "Có bắt máy",
  dontPickUp: "Không bắt máy",
  internal: "Gọi đi",
  external: "Gọi đến",
  caller: "Người gọi",
  callee: "Người nhận",
  filterResults: "kết quả lọc",
  searchResults: "kết quả tìm kiếm",
  results: "kết quả",
  keyword: "Từ khóa",
  minDuration: "Thời lượng tối thiểu",
  advancedSearch: "Tìm kiếm nâng cao",
  hideAdvancedSearch: "Ẩn tìm kiếm nâng cao",
  fromNumber: "Số gọi từ",
  toNumber: "Số gọi đến",
  agentNumber: "Số hiệu agent",
  calls: "Xem cuộc gọi",
  showMore: "Xem thêm",
  showLess: "Ẩn bớt",
  all: "Tất cả",
  uploadDateFuture: "Ngày tải lên phải nhỏ hơn hoặc bằng ngày hiện tại",
  followUp: "Cuộc gọi Follow Up",
  followUpNumber: "Số cuộc gọi Follow Up",
  totalWords: "Tổng số từ",
  phoneNumber: "Số điện thoại",
  noAudioFound: "Không có file Audio",
  noMetadataFound: "Không có file Metadata",
  noSpeechData: "Audio không có âm",
  monitor: "Theo dõi",
  totalLines: "Tổng cộng: {{lines}} dòng",
};
