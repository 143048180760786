import classNames from 'classnames'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { createEditor, Editor, Element, Range, Transforms } from 'slate'
import { Slate, Editable, withReact, ReactEditor } from 'slate-react'
import "./style.less"
import TranscriptTitle from './TranscriptTitle'
import { resetNodes, sentencesToSlateValue, sentencesToSlateValueDataTool, withTranscript, withTranscriptDataTool } from './util'
import scrollIntoView from 'scroll-into-view-if-needed'
import _, { isEqual } from 'lodash'
import TranscriptToolbar from './TranscriptToolbar'
import { Tooltip } from 'antd'
import useDataToolRole from 'hooks/useDataToolRole'
import DataToolTranscriptToolbar from './DataToolTranscriptToolbar'

const Leaf = ({ attributes, children, leaf }) => {
    return <Tooltip title={leaf.label}><span
        {...attributes}
        style={{
            color: leaf.textColor,
            backgroundColor: leaf.backgroundColor
        }}
        className={classNames({
            "highlight": leaf.highlight,
            "low-conf": leaf.lowConf,
            "changed": leaf.changed,
            "current": leaf.current,
            "hover": leaf.hover,
            "selected": leaf.selected
        })}>
        {children}
    </span>
    </Tooltip>
}

const TranscriptEditorV2 = ({ playTo, videoRef, onChange, jump, customHighlights = [], readOnly, sentences = [], dicts = [] }) => {
    const dataToolRole = useDataToolRole()
    const [editor] = useState(() => dataToolRole ? withTranscriptDataTool(withReact(createEditor())) : withTranscript(withReact(createEditor())));

    const highlightWords = useRef({});
    const [, setTime] = useState(0);
    const timeRef = useRef(0);
    const passFirst = useRef(false);

    const initialValue = useMemo(() => {
        const value = dataToolRole ? sentencesToSlateValueDataTool(sentences) : sentencesToSlateValue(sentences, dicts);
        onChange(value.map(child => child.sentence));
        return value;
    }, [sentences]);

    const customHighlightsRef = useRef(customHighlights);
    customHighlightsRef.current = customHighlights;

    useEffect(() => {
        if (!passFirst.current) {
            passFirst.current = true
        } else {
            resetNodes(editor, {
                nodes: initialValue
            })
        }
    }, [initialValue])

    const value = useRef(initialValue);

    const updateHighlight = useCallback(() => {
        const newHightlightWords = {};
        value.current.forEach((paragraph, paragraphIndex) => {
            const { sentence } = paragraph;
            if (sentence.start <= timeRef.current && sentence.end >= timeRef.current) {
                if (dataToolRole) {
                    newHightlightWords[paragraphIndex] = [0, sentence.transcript.length];
                } else {
                    const { words } = sentence;
                    words.forEach(word => {
                        if (word.start <= timeRef.current && word.end >= timeRef.current && word.word.trim().length !== 0) {
                            newHightlightWords[paragraphIndex] = [word.startIndex, word.endIndex];
                        }
                        if (word.end < timeRef.current) {
                            return;
                        }
                    })
                }
            }
            if (sentence.end < timeRef.current) {
                return;
            }
        })
        if (!isEqual(newHightlightWords, highlightWords)) {
            highlightWords.current = newHightlightWords;
        }
    }, [dataToolRole]);

    useEffect(() => {
        const requestAnimationFrame =
            window.requestAnimationFrame ||
            window.mozRequestAnimationFrame ||
            window.webkitRequestAnimationFrame ||
            window.msRequestAnimationFrame;

        const cancelAnimationFrame =
            window.cancelAnimationFrame || window.mozCancelAnimationFrame;

        let myReq;

        const toFixedDown = function (num, digits) {
            var re = new RegExp("(\\d+\\.\\d{" + digits + "})(\\d)"),
                m = num.toString().match(re);
            return m ? parseFloat(m[1]) : num.valueOf();
        };

        const updateTime = () => {
            if ((toFixedDown(timeRef.current, 2) !== toFixedDown(videoRef.current?.currentTime ?? 0, 2))) {
                timeRef.current = videoRef.current.currentTime;
                const currentHighlights = _.cloneDeep(highlightWords.current);
                updateHighlight();
                if (!_.isEqual(currentHighlights, highlightWords.current)) {
                    setTime(videoRef.current.currentTime);
                }
            }
            myReq = requestAnimationFrame(updateTime);
        }

        myReq = requestAnimationFrame(updateTime);
        return () => cancelAnimationFrame(myReq);
    }, []);

    const jumpTo = ([sentenceIndex, offset]) => {
        ReactEditor.focus(editor);
        Transforms.select(editor, { path: [sentenceIndex, 0], offset });
        setTimeout(() => Transforms.deselect(editor), 0);
    }

    useEffect(() => {
        if (jump) {
            jumpTo(jump);
        }
    }, [jump])

    useEffect(() => {
        if (highlightWords.current && Object.keys(highlightWords.current) > 0) {
            const sentenceIndex = Object.keys(highlightWords.current)[0];
            // console.log([parseInt(sentenceIndex), highlightWords.current[sentenceIndex][0]]);
            jumpTo([parseInt(sentenceIndex), highlightWords.current[sentenceIndex][0]])
        }
    }, [highlightWords.current])

    const decorate = useCallback(([node, path]) => {
        const ranges = [];
        if (Editor.isEditor(node)) {
            customHighlightsRef.current.forEach(({ from, to, properties }) => {
                ranges.push({
                    anchor: { path: [from[0], 0], offset: from[1] },
                    focus: { path: [to[0], 0], offset: to[1] },
                    ...properties
                })
            });
        }
        else if (Element.isElement(node)) {
            const sentence = node.sentence;
            const highlightWord = highlightWords.current[path[0]];
            if (highlightWord) {
                ranges.push({
                    anchor: { path: [path[0], 0], offset: highlightWord[0] },
                    focus: { path: [path[0], 0], offset: highlightWord[1] },
                    current: true
                })
            }
            if (!dataToolRole) {
                const { words } = sentence;

                words.forEach(word => {
                    if (word.changed) {
                        ranges.push({
                            anchor: { path: [path[0], 0], offset: word.startIndex },
                            focus: { path: [path[0], 0], offset: word.endIndex },
                            changed: true
                        })
                    }
                    if (word.conf < 0.5) {
                        ranges.push({
                            anchor: { path: [path[0], 0], offset: word.startIndex },
                            focus: { path: [path[0], 0], offset: word.endIndex },
                            lowConf: true
                        })
                    }
                })
            }
        }
        return ranges;
    }, [])


    const renderElement = useCallback((props) => {
        const sentence = props.element.sentence;

        return (
            <div
                className='transcriptEditor-row'
                {...props.attributes}
            >
                <TranscriptTitle playTo={playTo} readOnly={readOnly} videoRef={videoRef} sentence={sentence} />
                <div className='transcriptEditor-editor'>{props.children}</div>
            </div>
        )
    }, []);

    const renderLeaf = useCallback(({ attributes, children, leaf }) => {
        return <Leaf attributes={attributes} children={children} leaf={leaf} />
    }, []);

    return (
        <div className='newTranscriptEditor' style={{ position: "relative" }}>
            <Slate
                editor={editor}
                value={initialValue}
                onChange={newValue => {
                    if (!_.isEqual(newValue, value.current)) {
                        highlightWords.current = {};
                        customHighlightsRef.current = [];
                        if (onChange) {
                            onChange(newValue.map(child => child.sentence));
                        }
                    }
                    value.current = newValue;
                }}
            >
                {dataToolRole ? <DataToolTranscriptToolbar vidRef={videoRef} /> : <TranscriptToolbar vidRef={videoRef} />}
                <Editable
                    readOnly={readOnly}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            const selection = editor.selection;
                            if (selection && Range.isCollapsed(selection)) {
                                const before = Editor.before(editor, selection, {
                                    unit: "character"
                                })
                                const after = Editor.after(editor, selection, {
                                    unit: "character"
                                })
                                const charBefore = before && Editor.string(editor, {
                                    anchor: selection.anchor,
                                    focus: before
                                });
                                const charAfter = after && Editor.string(editor, {
                                    anchor: selection.anchor,
                                    focus: after
                                });
                                if (((!charAfter || charAfter === " ") && charBefore !== " ") || (charAfter !== " " && (charBefore === " "))) {
                                    return;
                                }
                            }
                            e.preventDefault();
                        }
                    }}
                    onFocus={(e) => {
                        e.preventDefault();
                    }}
                    scrollSelectionIntoView={(editor, domRange) => {
                        // This was affecting the selection of multiple blocks and dragging behavior,
                        // so enabled only if the selection has been collapsed.
                        if (
                            domRange.getBoundingClientRect &&
                            (!editor.selection ||
                                (editor.selection && Range.isCollapsed(editor.selection)))
                        ) {
                            const leafEl = domRange.startContainer.parentElement;
                            leafEl.getBoundingClientRect = domRange.getBoundingClientRect.bind(domRange)
                            scrollIntoView(leafEl, {
                                // behavior: "smooth",
                                scrollMode: 'if-needed',
                            })

                            // @ts-expect-error an unorthodox delete D:
                            delete leafEl.getBoundingClientRect
                        }
                    }}
                    spellCheck={false}
                    decorate={decorate}
                    className='transcriptEditor'
                    renderElement={renderElement}
                    renderLeaf={renderLeaf}
                />
            </Slate>
        </div>
    )
};

export default React.memo(TranscriptEditorV2);