/* eslint-disable react-hooks/rules-of-hooks */

import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SaveOutlined, DownloadOutlined, RetweetOutlined, FilterOutlined } from '@ant-design/icons';
import mockTranscript from 'config/podcast25.json';

import _get from 'lodash/get';
import {
  Button,
  Row,
  Col,
  Typography,
  Divider,
  Input,
  Popover,
  Slider,
  TimePicker,
  message,
  Dropdown,
  Menu,
  Skeleton,
  Space,
} from 'antd';
import {
  ArrowUpOutlined,
  ArrowDownOutlined,
  StepForwardOutlined,
  EllipsisOutlined,
  PauseOutlined,
  PlayCircleOutlined,
  WifiOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import TranscriptEditor from 'components/TranscriptEditor';
import TrackKeyBoard from 'components/TrackKeyboard';
import { exportToJsonFile, formatTime, convertToDatetime, exportToTextFile, filterSentences } from 'utils/utils';
import './styles.less';
import { db } from 'utils/firebase';
import useAuth from 'hooks/useAuth';
import { replaceSentencesWithDict } from 'utils/editor';
import { useTranslation } from 'react-i18next';
import { getLanguages } from 'utils/lang';
import { IS_NTTDATA, MAX_SPEAKER_SHOW } from 'utils/constants';
import { storage } from 'utils/firebase';
import { Wave } from '@foobar404/wave';
import moment from 'moment';
import _ from 'lodash';

const { Text } = Typography;
const { TextArea } = Input;

// Check url return true if video, false if audio
const checkUrl = (url) => {
  // match regex for video from firebase storage
  const regex =
    /https:\/\/firebasestorage\.googleapis\.com\/v0\/b\/(.*?)\/o\/(.*?)\.(mp4|webm|ogg|ogv|avi|wmv|mov|flv|3gp|mkv|mpeg|mpg)\?alt=media/;
  return regex.test(url);
};

const stateDefault = {
  playing: false,
  volume: 0.8,
  muted: false,
  played: 0,
  duration: 0,
  playedString: '',
  durationString: '',
  loaded: 0,
  playbackRate: 1.0,
};

let start_timestamp;

const MeetingDetail = () => {
  //ref
  const mediaRef = useRef();
  const { user, loading } = useAuth();

  // Wave Visualizer
  let canvasElm = useRef();

  // state
  const [mediaUrl, setMediaUrl] = useState('');
  const [sentences, setSentences] = useState([]);
  const [speaking, setSpeaking] = useState({});
  const [replacedDict, setReplacedDict] = useState(false);
  const [mediaState, setMediaState] = useState(stateDefault);
  const [findReplaceVisible, setFindReplaceVisible] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [keyboardVisible, setKeyboardVisible] = useState(false);
  const [loadingTranscription, setLoadingTranscription] = useState(true);
  const [loadingRevert, setLoadingRevert] = useState(false);
  const [filterAll, setFilterAll] = useState(true);
  const { t, i18n } = useTranslation();
  const lang = getLanguages(t);
  const [jumpHour, setJumpHour] = useState(0);
  const [jumpMinute, setJumpMinute] = useState(0);
  const [jumpSecond, setJumpSecond] = useState(0);
  const [notes, setNotes] = useState('');

  console.log('sentences', sentences);

  const id = useRef();
  const latestSentencesRef = useRef(sentences);
  latestSentencesRef.current = sentences;

  //Hook
  const { meetingId: videoId, mode } = useParams();

  const handlePlayPause = () => {
    if (mediaRef.current && (mediaRef.current?.paused || mediaRef.current?.ended)) {
      mediaRef.current.play();
    } else {
      mediaRef.current.pause();
    }
  };

  const handleSeekChange = (value) => {
    const timeInSeconds = value / 10000000;
    const time = formatTime(timeInSeconds);
    if (mediaRef.current) mediaRef.current.pause();
    setMediaState((e) => ({
      ...e,
      played: timeInSeconds,
      playedString: `${time.hours !== '00' ? `${time.hours}:` : ''}${time.minutes}:${time.seconds}`,
    }));
  };

  const handleSeekMouseUp = (value) => {
    if (mediaRef.current) mediaRef.current.play();
    mediaRef.current.currentTime = value / 10000000;
  };

  const handleJumpChange = () => {
    const timeInSeconds = +jumpHour * 3600 + +jumpMinute * 60 + +jumpSecond;
    const durationString = formatTime(mediaRef.current.duration);
    const timeString = formatTime(timeInSeconds);

    if (timeInSeconds > mediaState.duration) {
      setMediaState((e) => ({
        ...e,
        played: mediaState.duration,
        playedString: `${durationString.hours !== '00' ? `${durationString.hours}:` : ''}:${durationString.minutes}:${
          durationString.seconds
        }`,
      }));
      mediaRef.current.currentTime = mediaState.duration;
      message.success(lang.jumpToEndSuccess);
    } else {
      setMediaState((e) => ({
        ...e,
        played: timeInSeconds,
        playedString: `${timeString.hours !== '00' ? `${timeString.hours}:` : ''}:${timeString.minutes}:${
          timeString.seconds
        }`,
      }));
      mediaRef.current.currentTime = timeInSeconds;
      message.success(lang.jumpToNewTimestampSuccess);
    }
  };

  const updateVolume = (value) => {
    setMediaState((e) => ({
      ...e,
      volume: value / 10,
    }));
    if (mediaRef.current?.muted) {
      mediaRef.current.muted = false;
    }
    mediaRef.current.volume = value / 10;
  };

  const updatePlaybackRate = () => {
    const playbackRate = mediaState.playbackRate;
    if (playbackRate === 3) {
      setMediaState((e) => ({
        ...e,
        playbackRate: 1,
      }));
      mediaRef.current.playbackRate = 1;
    } else {
      setMediaState((e) => ({
        ...e,
        playbackRate: playbackRate + 1,
      }));
      mediaRef.current.playbackRate = playbackRate + 1;
    }
  };

  const changeTime = useCallback(
    (value) => {
      if (mode !== 'edit') return;
      const time = formatTime(value);
      setMediaState((e) => ({
        ...e,
        played: value,
        playedString: `${time.hours !== '00' ? `${time.hours}:` : ''}${time.minutes}:${time.seconds}`,
      }));
      mediaRef.current.currentTime = value;
      if (mediaRef.current.paused) {
        mediaRef.current.play();
      }
    },
    [mode]
  );

  const handleExportClick = async (key) => {
    const date = moment(
      await (
        await db
          .collection(IS_NTTDATA ? 'meetings' : 'files')
          .doc(videoId)
          .get()
      )
        .data()
        .createdAt?.toDate()
    ).format('ll');
    switch (key) {
      case 0:
        exportToTextFile(
          sentences,
          `${date}_${
            IS_NTTDATA
              ? videoId.includes('_')
                ? videoId.split('_').slice(0, -1).join('_')
                : videoId
              : fileName.split('.')[0]
          }`,
          true,
          {
            started: lang.transcriptionStarted,
            stopped: lang.transcriptionStopped,
            close: lang.meetingClose,
          },
          i18n.language
        );
        break;
      case 1:
        exportToTextFile(
          sentences,
          `${date}_${
            IS_NTTDATA
              ? videoId.includes('_')
                ? videoId.split('_').slice(0, -1).join('_')
                : videoId
              : fileName.split('.')[0]
          }`,
          false,
          {
            started: lang.transcriptionStarted,
            stopped: lang.transcriptionStopped,
            close: lang.meetingClose,
          }
        );
        break;
      case 2:
        exportToJsonFile({ sentences }, videoId.includes('_') ? videoId.split('_').slice(0, -1).join('_') : videoId);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (!canvasElm.current) return;
    if (!mediaRef) return;

    let audioElement = document.querySelector('#audio');
    const temp = new Wave(audioElement, canvasElm.current);
    canvasElm.current.style.width = '100%';
    canvasElm.current.style.height = '300px';

    temp.addAnimation(
      new temp.animations.Lines({
        fillColor: '#4a90e2',
        lineColor: '#4a90e2',
        center: true,
        mirroredY: true,
      })
    );
  }, [mediaRef]);

  if (IS_NTTDATA) {
    useEffect(() => {
      // If the user is at the bottom of the transcript, scroll to the bottom
      // when new sentences are added

      const oldScrollHeight = document.getElementsByClassName('edit-leftcol')[0].scrollHeight;

      return () => {
        const ele = document.getElementsByClassName('edit-leftcol')[0];
        const { scrollTop, scrollHeight, clientHeight } = ele;
        // console.log(scrollTop + clientHeight, scrollHeight, oldScrollHeight);
        if (Math.abs(scrollTop + clientHeight - oldScrollHeight) <= 10) {
          // console.log("scroll to bottom");
          if (ele)
            ele.scrollTo({
              top: scrollHeight,
              behavior: 'auto',
            });
        }
      };
    }, [sentences, loadingTranscription, speaking]);

    useEffect(() => {
      if (mode === 'edit') return;
      db.collection('meetings')
        .doc(videoId)
        .collection('sentences')
        .orderBy('timestamp', 'asc')
        .onSnapshot((snapshot) => {
          setSentences((e) => {
            const data = snapshot.docs.map((doc) => {
              const data = doc.data();

              return {
                id: doc.id,
                ...data,
                checked: e.find((e) => e.id === doc.id)?.checked || false,
                words: !_.isEmpty(data.words)
                  ? data.words
                  : [
                      {
                        start: data.start,
                        end: data.end,
                        word: data.transcript,
                        conf: 1,
                      },
                    ],
              };
            });
            return filterSentences(data);
          });
        });

      db.collection('meetings')
        .doc(videoId)
        .collection('speakers')
        .onSnapshot((snapshot) => {
          const speakingOBj = {};
          snapshot.docs.forEach((doc) => {
            speakingOBj[doc.id] = doc.data().is_speaking;
          });
          setSpeaking(speakingOBj);
        });
    }, [videoId, mode]);

    useEffect(() => {
      if (!user) return;

      (async () => {
        const audioLength = await (await db.collection('meetings').doc(videoId).get()).data()?.length;

        if (audioLength) {
          storage
            .ref()
            .child(`${videoId}/full.wav`)
            .getDownloadURL()
            .then((url) => {
              setMediaUrl(url);
              const time = formatTime(audioLength);
              setMediaState((e) => ({
                ...e,
                duration: audioLength,
                durationString: `${time.hours !== '00' ? `${time.hours}:` : ''}${time.minutes}:${time.seconds}`,
              }));
            });
        } else {
          if (mode === 'edit') setSentences(null);
          return;
        }

        await db
          .collection('meetings')
          .doc(videoId)
          .get()
          .then((doc) => {
            if (doc.exists) {
              const data = doc.data();
              start_timestamp = data.start_timestamp;
              setNotes(data.notes);
            }
          });

        db.collection('meetings')
          .doc(videoId)
          .collection('sentencesEdited')
          .orderBy('timestamp', 'asc')
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.empty) {
              db.collection('meetings')
                .doc(videoId)
                .collection('sentences')
                .orderBy('timestamp', 'asc')
                .get()
                .then((orgQuerySnapshot) => {
                  if (orgQuerySnapshot.empty) {
                    message.error('No transcript found');
                    setSentences(null);
                    return;
                  }

                  const curMeetingDoc = db.collection('meetings').doc(videoId);

                  curMeetingDoc.set({ lastEdited: new Date() }, { merge: true });

                  const data = orgQuerySnapshot.docs.map((doc) => {
                    let docData = doc.data();

                    if (
                      !docData.status &&
                      (!docData.words || !Array.isArray(docData.words) || docData.words.length === 0)
                    ) {
                      docData.words = [];
                      docData.words.push({
                        start: docData.start,
                        end: docData.end,
                        word: docData.transcript,
                        conf: 1,
                      });
                    }

                    curMeetingDoc
                      .collection('sentencesEdited')
                      .doc(doc.id)
                      .set({ ...docData, checked: false });
                    return { ...docData, id: doc.id, checked: false };
                  });

                  const audioTranscripts = start_timestamp
                    ? data.map((e) => {
                        const start = e.timestamp - start_timestamp;
                        return {
                          ...e,
                          words:
                            e.words?.map((word) => {
                              return {
                                ...word,
                                start: word.start - e.start + start,
                                end: word.end - e.start + start || 0,
                              };
                            }) || [],
                          start: start,
                          end: start + e.duration || 0,
                        };
                      })
                    : data;

                  setSentences(filterSentences([...audioTranscripts]));
                });
            } else {
              let data = querySnapshot.docs.map((doc) => {
                let docData = doc.data();

                if (
                  !docData.status &&
                  (!docData.words || !Array.isArray(docData.words) || docData.words.length === 0)
                ) {
                  docData.words = [];
                  docData.words.push({
                    start: docData.start,
                    end: docData.end,
                    word: docData.transcript,
                    conf: 1,
                  });
                }

                return {
                  ...docData,
                  id: doc.id,
                };
              });

              const audioTranscripts = start_timestamp
                ? data.map((e) => {
                    const start = e.timestamp - start_timestamp;
                    return {
                      ...e,
                      words:
                        e.words?.map((word) => {
                          return {
                            ...word,
                            start: word.start - e.start + start,
                            end: word.end - e.start + start || 0,
                          };
                        }) || [],
                      start: start,
                      end: start + e.duration || 0,
                    };
                  })
                : data;

              setSentences(filterSentences([...audioTranscripts]));
            }
          });
      })();

      localStorage.removeItem(`nami-transcript-${videoId}`);
    }, [videoId, user, mode]);

    useEffect(() => {
      if (loadingTranscription && user && (!sentences || sentences.length > 0)) {
        if (!replacedDict && sentences && sentences.length > 0) {
          db.collection('dicts')
            .where('userId', '==', user.uid)
            .get()
            .then((querySnapshot) => {
              const dicts = querySnapshot.docs.map((doc) => ({
                ...doc.data(),
              }));
              setReplacedDict(true);
              setSentences((e) => replaceSentencesWithDict(e, dicts));
              setLoadingTranscription(false);
              message.success(lang.replacedDict);
            });
        } else {
          setLoadingTranscription(false);
        }
      }
    }, [sentences, replacedDict, loadingTranscription, user, lang.replacedDict]);
  } else {
    useEffect(() => {
      if (user && sentences.length > 0) {
        if (!replacedDict) {
          setReplacedDict(true);
          setLoadingTranscription(true);
          db.collection('dicts')
            .where('userId', '==', user?.uid)
            .orderBy('sortBy', 'asc')
            .get()
            .then(async (data) => {
              let dicts = await Promise.all(
                data.docs.map((doc) => {
                  let item = doc.data();
                  return {
                    dictId: doc.id,
                    ...item,
                  };
                })
              );

              setSentences((s) => replaceSentencesWithDict(s, dicts));
              setLoadingTranscription(false);
            })
            .catch(() => {
              setLoadingTranscription(false);
            });
        }
        return;
      }
      localStorage.removeItem(`nami-transcript-${videoId}`);
    }, [videoId, user, replacedDict, sentences]);

    useEffect(() => {
      if (videoId) {
        db.collection('files')
          .doc(videoId)
          .get()
          .then((doc) => {
            if (doc.empty) {
              console.log('No matching documents.');
              return;
            }

            const data = doc.data();
            setFileName(data.name);
            setLoadingTranscription(true);
            setMediaUrl(data.url);
            setNotes(data.notes);
            const time = formatTime(data.length);
            setMediaState((e) => ({
              ...e,
              duration: data.length,
              durationString: `${time.hours !== '00' ? `${time.hours}:` : ''}${time.minutes}:${time.seconds}`,
            }));
            id.current = doc.id;

            db.collection('files')
              .doc(doc.id)
              .collection('sentencesEdited')
              .orderBy('start', 'asc')
              .get()
              .then((sentencesEdited) => {
                if (sentencesEdited.empty) {
                  db.collection('files')
                    .doc(doc.id)
                    .collection('sentences')
                    .orderBy('start', 'asc')
                    .get()
                    .then((sentences) => {
                      if (sentences.empty) {
                        setSentences([]);
                      } else {
                        const transcriptionSentences = sentences.docs.map((doc) => {
                          const data = doc.data();

                          return {
                            id: doc.id,
                            ...data,
                            checked: !!latestSentencesRef.current.find((e) => e.id === doc.id)?.checked,
                            words: !_.isEmpty(data.words)
                              ? data.words
                              : [
                                  {
                                    start: data.start,
                                    end: data.end,
                                    word: data.transcript,
                                    conf: 1,
                                  },
                                ],
                          };
                        });
                        setSentences(transcriptionSentences);
                      }
                    });
                } else {
                  setSentences(
                    sentencesEdited.docs.map((doc) => {
                      const data = doc.data();

                      return {
                        id: doc.id,
                        ...data,
                        checked: !!latestSentencesRef.current.find((e) => e.id === doc.id)?.checked,
                        words: !_.isEmpty(data.words)
                          ? data.words
                          : [
                              {
                                start: data.start,
                                end: data.end,
                                word: data.transcript,
                                conf: 1,
                              },
                            ],
                      };
                    })
                  );
                }
              });
          });
      }
    }, [videoId]);
  }

  useEffect(() => {
    if (mode !== 'edit') return;
    // --------------------video---------------------------------
    const videoCurrent = mediaRef.current;
    if (!mediaUrl) {
      return;
    }

    if (videoCurrent.readyState === 0) {
      videoCurrent.load();
    }

    const handleTimeUpdate = () => {
      if (!videoCurrent?.seeking) {
        const time = formatTime(Math.floor(videoCurrent?.currentTime));
        setMediaState((e) => ({
          ...e,
          played: videoCurrent?.currentTime,
          playedString: `${time.hours !== '00' ? `${time.hours}:` : ''}${time.minutes}:${time.seconds}`,
        }));
      }
    };
    // handle video playing time
    if (videoCurrent) videoCurrent.addEventListener('timeupdate', handleTimeUpdate);
    return () => {
      videoCurrent.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [mediaUrl, mode]);

  const jumpContent = (
    <div
      style={{
        display: 'flex',
        borderRadius: '5px',
        border: '1px solid rgba(51, 51, 51, 0.15)',
      }}
    >
      <div
        style={{
          width: '70px',
          height: '70px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          borderRight: '1px solid rgba(51, 51, 51, 0.15)',
        }}
      >
        <div style={{ textAlign: 'center', marginBottom: '6px' }}>{lang.hour}</div>
        <input
          style={{
            border: '0px',
            width: '24px',
            height: 'fit',
            fontSize: '16px',
            textAlign: 'center',
          }}
          type='number'
          min='0'
          max='23'
          onInput={(e) => setJumpHour(e.target.value)}
        />
      </div>
      <div
        style={{
          width: '70px',
          height: '70px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          borderRight: '1px solid rgba(51, 51, 51, 0.15)',
        }}
      >
        <div style={{ textAlign: 'center', marginBottom: '6px' }}>{lang.minute}</div>
        <input
          style={{
            border: '0px',
            width: '24px',
            height: 'fit',
            fontSize: '16px',
            textAlign: 'center',
          }}
          type='number'
          min='0'
          max='59'
          onInput={(e) => setJumpMinute(e.target.value)}
        />
      </div>
      <div
        style={{
          width: '70px',
          height: '70px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          borderRight: '1px solid rgba(51, 51, 51, 0.15)',
        }}
      >
        <div style={{ textAlign: 'center', marginBottom: '6px' }}>{lang.second}</div>
        <input
          style={{
            border: '0px',
            width: '24px',
            height: 'fit',
            fontSize: '16px',
            textAlign: 'center',
          }}
          type='number'
          min='0'
          max='59'
          onInput={(e) => setJumpSecond(e.target.value)}
        />
      </div>
      <button
        style={{
          width: '70px',
          height: '70px',
          border: '0px',
          color: '#4a90e2',
          fontWeight: 'bold',
          position: 'relative',
          top: '-0.25px',
          right: '-0.25px',
        }}
        onClick={() => {
          handleJumpChange();
        }}
      >
        <CheckOutlined style={{ fontSize: '20px' }} />
        <div>{lang.apply}</div>
      </button>
    </div>
  );

  const volumeContent = <Slider min={0} max={10} onChange={updateVolume} value={mediaState.volume * 10} />;
  const filterMenu = (
    <Menu selectable selectedKeys={`download-item-${Number(!filterAll)}`}>
      {[lang.showAll, lang.showOnlySelected].map((item, index) => (
        <Menu.Item key={`download-item-${index}`} onClick={() => setFilterAll(!Boolean(index))}>
          {item}
        </Menu.Item>
      ))}
    </Menu>
  );
  const exportMenu = (
    <Menu>
      {(IS_NTTDATA ? [lang.exportAll, lang.exportSelected, lang.dev] : [lang.exportAll, lang.dev]).map(
        (item, index) => (
          <Menu.Item key={`download-item-${index}`} onClick={() => handleExportClick(index)}>
            {item}
          </Menu.Item>
        )
      )}
    </Menu>
  );

  const someOneIsSpeaking = Object.values(speaking).some((e) => e);
  const fileLabel = !IS_NTTDATA
    ? fileName
    : videoId.includes('_')
    ? videoId.split('_').slice(0, -1).join('_')
    : videoId;
  const isVideo = checkUrl(mediaUrl);

  return (
    <div className='edit'>
      <Skeleton loading={loadingTranscription && loading} paragraph>
        <Row className='edit-header' justify='space-between' align='middle'>
          <Col
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-start',
            }}
          >
            <span
              style={{
                marginLeft: '18px',
              }}
            >
              {IS_NTTDATA && lang.meeting + ' ' + lang.ID + ': '} <b>{fileLabel}</b>
            </span>
          </Col>
          <Col style={{ padding: '0 2rem' }}>
            <Space>
              {IS_NTTDATA && (
                <Dropdown overlay={filterMenu}>
                  <Button size='large' htmlType='submit' type='link' icon={<FilterOutlined />}>
                    {lang.filter}
                  </Button>
                </Dropdown>
              )}
              {/* {mode === "edit" && (
                <Button
                  size="large"
                  htmlType="submit"
                  type="link"
                  icon={<RetweetOutlined />}
                  onClick={revertTranscriptionHandle}
                  loading={loadingRevert}
                >
                  {lang.revert}
                </Button>
              )} */}
              <Dropdown overlay={exportMenu}>
                <Button size='large' htmlType='submit' type='link' icon={<DownloadOutlined />}>
                  {lang.export}
                </Button>
              </Dropdown>
            </Space>
          </Col>
        </Row>
      </Skeleton>
      <Divider style={{ margin: 0 }} />
      {/*-------------------------------------------------- video Player-------------------------------------------------- */}
      <Row className='edit-row'>
        {mode === 'edit' && (
          <Col span={7} className='edit-rightcol'>
            <div className='edit-video'>
              {isVideo ? (
                <video
                  ref={mediaRef}
                  src={mediaUrl}
                  className='edit-video-player'
                  preload='metadata'
                  onClick={handlePlayPause}
                  onPause={() => setMediaState((e) => ({ ...e, playing: false }))}
                  onPlay={() => setMediaState((e) => ({ ...e, playing: true }))}
                  crossOrigin='anonymous'
                ></video>
              ) : (
                <>
                  <audio
                    ref={mediaRef}
                    src={mediaUrl}
                    className='edit-video-player'
                    id='audio'
                    preload='metadata'
                    onClick={handlePlayPause}
                    onPause={() => setMediaState((e) => ({ ...e, playing: false }))}
                    onPlay={() => setMediaState((e) => ({ ...e, playing: true }))}
                    crossOrigin='anonymous'
                  ></audio>
                  <canvas ref={canvasElm}></canvas>
                </>
              )}
            </div>
            <div className='edit-video-progress'>
              <Slider
                min={0}
                max={mediaState.duration * 10000000}
                tooltipVisible={false}
                value={mediaState.played * 10000000}
                onChange={handleSeekChange}
                onAfterChange={handleSeekMouseUp}
                className='edit-video-progress-slider'
              />
              <div className='time'>
                <Text type='secondary'>
                  <time id='time-elapsed' dateTime={convertToDatetime(mediaState.playedString)}>
                    {mediaState.playedString ? mediaState.playedString : '00:00'}
                  </time>
                  <span> / </span>
                  <time id='duration' dateTime={convertToDatetime(mediaState.durationString)}>
                    {mediaState.durationString ? mediaState.durationString : '00:00'}
                  </time>
                </Text>
              </div>
            </div>

            <div className='edit-video-btn'>
              <div className='edit-video-btn-item' onClick={handlePlayPause}>
                <span className='edit-video-btn-item-icon'>
                  {mediaState.playing ? <PauseOutlined /> : <PlayCircleOutlined />}
                </span>
                <span className='edit-video-btn-item-text'>{mediaState.playing ? lang.pause : lang.play}</span>
              </div>

              <Popover content={jumpContent} title={lang.jumpToTimestamp}>
                <div className='edit-video-btn-item'>
                  <span className='edit-video-btn-item-icon'>
                    <StepForwardOutlined />
                  </span>
                  <span className='edit-video-btn-item-text'>{lang.jumpTo}</span>
                </div>
              </Popover>

              <div className='edit-video-btn-item' onClick={updatePlaybackRate}>
                <span className='edit-video-btn-item-icon'>
                  <Text className='edit-video-btn-item-icon-speed anticon'>{mediaState.playbackRate}x</Text>
                </span>
                <span className='edit-video-btn-item-text'>{lang.speed}</span>
              </div>

              <Popover content={volumeContent} title={lang.setVolume}>
                <div className='edit-video-btn-item'>
                  <span className='edit-video-btn-item-icon'>
                    <WifiOutlined rotate={90} />
                  </span>
                  <span className='edit-video-btn-item-text'>{lang.volume}</span>
                </div>
              </Popover>
            </div>

            {/* ----------------------Text Area-------------------------------------- */}
            <div className='edit-video-note' direction='vertical'>
              <label htmlFor='notes-area'>
                <Text style={{ textTransform: 'uppercase' }}>{lang.notes}</Text>
              </label>
              <TextArea
                id='notes-area'
                className='edit-video-note-textarea'
                placeholder={lang.writeNotesHere}
                value={notes}
                readOnly={true}
              />
            </div>
          </Col>
        )}
        <Col
          span={17}
          style={
            mode === 'edit'
              ? {}
              : {
                  width: '100%',
                  maxWidth: 'unset',
                  flex: 'unset',
                  position: 'relative',
                }
          }
          className='edit-leftcol'
        >
          <Skeleton loading={loadingTranscription} paragraph={{ rows: 15 }}>
            {sentences ? (
              <TranscriptEditor
                mode={mode}
                filterAll={filterAll}
                changeTime={changeTime}
                sentences={sentences}
                setSentences={setSentences}
                mediaRef={mediaRef}
                videoId={videoId}
                dicts={[]}
                isSpeaking={speaking}
              />
            ) : (
              <div>Not found</div>
            )}
          </Skeleton>
          {someOneIsSpeaking && (
            <div className='speaking-status'>
              <svg className='speaking-status-icon' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
                <path
                  d='M9,14a4,4,0,1,0-4-4A4,4,0,0,0,9,14ZM9,8a2,2,0,1,1-2,2A2,2,0,0,1,9,8ZM6,15h6a4,4,0,0,1,4,4v2a1,1,0,0,1-2,0V19a2,2,0,0,0-2-2H6a2,2,0,0,0-2,2v2a1,1,0,0,1-2,0V19A4,4,0,0,1,6,15Zm11.462-5A5.977,5.977,0,0,1,15.7,14.253a1,1,0,0,1-1.414-1.414,4.015,4.015,0,0,0,0-5.678A1,1,0,1,1,15.7,5.747,5.977,5.977,0,0,1,17.462,10Zm-.181,7.7a1,1,0,0,1,.024-1.414,8.667,8.667,0,0,0,0-12.562A1,1,0,0,1,18.7,2.281a10.667,10.667,0,0,1,0,15.438,1,1,0,0,1-1.414-.024Z'
                  fill='currentColor'
                />
              </svg>
              {Object.entries(speaking)
                .map(([key, value], index) => {
                  const rv =
                    index < MAX_SPEAKER_SHOW
                      ? key
                      : index === MAX_SPEAKER_SHOW
                      ? `and ${Object.keys(speaking).length - index} more...`
                      : null;
                  return value ? rv : null;
                })
                .filter((item) => item !== null)
                .join(', ')}
              <div className='wave-container'>
                <div className='line line1'></div>
                <div className='line line2'></div>
                <div className='line line3'></div>
                <div className='line line4'></div>
                <div className='line line5'></div>
              </div>
            </div>
          )}
          <div className='navigate-container'>
            <Button
              type='primary'
              onClick={() => {
                const ele = document.getElementsByClassName('edit-leftcol')[0];
                ele.scrollTo({
                  top: 0,
                  behavior: 'smooth',
                });
              }}
            >
              <ArrowUpOutlined />
            </Button>
            <Button
              type='primary'
              onClick={() => {
                const ele = document.getElementsByClassName('edit-leftcol')[0];
                ele.scrollTo({
                  top: ele.scrollHeight,
                  behavior: 'smooth',
                });
              }}
            >
              <ArrowDownOutlined />
            </Button>
          </div>
        </Col>
      </Row>
      <TrackKeyBoard
        keyboardVisible={keyboardVisible}
        setKeyboardVisible={setKeyboardVisible}
        setFindReplaceVisible={setFindReplaceVisible}
      />
    </div>
  );
};
export default MeetingDetail;
