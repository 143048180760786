import { Button, Checkbox, Form, Input, Popover, Typography } from "antd";
import React from "react";
import { CaretRightOutlined } from "@ant-design/icons";
import { formatTimeToString, speakerToString } from "utils/utils";
import { useSlateStatic } from "slate-react";
import { changeTime } from "./util";
import useDataToolRole from "hooks/useDataToolRole";

const TranscriptTile = ({ sentence, videoRef, readOnly, playTo }) => {
  const speakerString = speakerToString(sentence.speaker);
  const editor = useSlateStatic();
  const dataToolRole = useDataToolRole();

  const handleChangeSpeaker = (all, speaker) => {
    editor.apply({
      type: "change_speaker",
      sentence,
      speaker,
      all,
    });
  };

  const content = (
    <Form
      requiredMark={false}
      layout="inline"
      name="basic"
      onFinish={(value) => handleChangeSpeaker(value.changeAll, value.speaker)}
    >
      {dataToolRole ? (
        <Form.Item style={{ marginRight: 8 }}>
          Change <strong>{speakerString}</strong> to:
        </Form.Item>
      ) : (
        <Form.Item
          style={{ marginRight: 8 }}
          name="changeAll"
          valuePropName="checked"
        >
          <Checkbox disabled>
            Change all <strong>{speakerString}</strong> to:
          </Checkbox>
        </Form.Item>
      )}
      <Form.Item
        name="speaker"
        rules={[
          {
            whitespace: true,
            required: true,
            message: "Please input speaker",
          },
        ]}
      >
        <Input value={sentence.speaker} />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Change
        </Button>
      </Form.Item>
    </Form>
  );

  return (
    <div
      className="transcriptEditor-title"
      style={{ userSelect: "none", WebkitUserSelect: "none" }}
      contentEditable={false}
    >
      <Popover content={readOnly ? null : content} trigger="click">
        <span className="transcriptEditor-title-speaker">{speakerString}</span>
      </Popover>
      <Typography.Text
        className="transcriptEditor-title-time"
        type="secondary"
        onClick={(e) => {
          changeTime(videoRef.current, sentence.start);
          if (e.shiftKey) {
            playTo.current = sentence.end;
          } else {
            playTo.current = null;
          }
        }}
      >
        <CaretRightOutlined />
        {formatTimeToString(sentence.start)}
        {dataToolRole && <> - {formatTimeToString(sentence.end)}</>}
      </Typography.Text>
    </div>
  );
};

export default TranscriptTile;
