import React, { useState, useEffect, useRef } from "react";

import {
  Layout,
  Table,
  Row,
  Col,
  Card,
  Upload,
  Input,
  Button,
  Form,
  Tag,
  Modal,
  Dropdown,
  Menu,
  Space,
  message,
  DatePicker,
} from "antd";
import {
  DownloadOutlined,
  SyncOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  EyeOutlined,
  ExclamationCircleOutlined,
  DeleteOutlined,
  DownOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import moment from "moment";
import { db, storage } from "utils/firebase";
import useAuth from "hooks/useAuth";

import { useTranslation } from "react-i18next";
import { getLanguages } from "utils/lang";

import * as uuid from "uuid";

import "./styles.less";
import axios from "axios";
import { getVideo } from "apis/video";
import { MeiliSearch } from "meilisearch";
import Search from "antd/lib/input/Search";
import { IS_FUNIX } from "utils/constants";

import useDataToolRole from "hooks/useDataToolRole";
import AssignForm from "./AssignForm";

import { saveAs } from "file-saver";
import JSZip from "jszip";
import { getUrlExtension } from "utils/utils";
import { chunk } from "lodash";

const { confirm } = Modal;

const { Dragger } = Upload;

const {
  REACT_APP_TENANT_ID,
  REACT_APP_FUNIX_API,
  REACT_APP_MEILI_HOST,
  REACT_APP_MEILI_API_KEY,
} = process.env;

const searchClient = IS_FUNIX
  ? new MeiliSearch({
      host: REACT_APP_MEILI_HOST,
      apiKey: REACT_APP_MEILI_API_KEY,
    })
  : null;

const optionsData = [
  {
    label: "onePerson",
    data: {
      number_speaker: 1,
      number_channel: 1,
      pipeline: "old",
    },
  },
  {
    label: "twoPeopleMonoChannelWithoutOverlap",
    data: {
      number_speaker: 2,
      number_channel: 1,
      pipeline: "old",
    },
  },
  {
    label: "twoPeopleMonoChannel",
    data: {
      number_speaker: 2,
      number_channel: 1,
      pipeline: "new",
    },
  },
  {
    label: "twoPeopleStereoChannels",
    data: {
      number_speaker: 2,
      number_channel: 2,
      pipeline: "old",
    },
  },
  {
    label: "twoPeopleOrMore",
  },
];

const isValidUrl = (urlString) => {
  try {
    return Boolean(new URL(urlString));
  } catch (e) {
    return false;
  }
};

const DataToolTag = ({ file, role }) => {
  if (
    (role === "annotator" && file.annotatorSubmitted) ||
    (role === "reviewer" && file.reviewerSubmitted) ||
    (role === "approver" && file.approverSubmitted)
  ) {
    return <Tag color="#87d068">Done</Tag>;
  }

  if (
    (role === "annotator" && file.annotatorEdited) ||
    (role === "reviewer" && file.reviewerEdited) ||
    (role === "approver" && file.approverEdited)
  ) {
    return <Tag color="#108ee9">Doing</Tag>;
  }

  if (
    (role === "annotator" && file.annotator) ||
    (role === "reviewer" && file.reviewer) ||
    (role === "approver" && file.approver)
  ) {
    return <Tag color="#ff9300">Assigned</Tag>;
  }

  return (
    <Tag style={{ color: "black" }} color="#DDE6ED">
      New
    </Tag>
  );
};

const SaasTranscripts = ({ baseUrl = "/user/transcripts", lang = "vn" }) => {
  const { t, i18n } = useTranslation();
  const language = getLanguages(t);

  const { user, token } = useAuth();

  const [files, setFiles] = useState([]);
  const [filterFiles, setFilterFiles] = useState([]);
  const [loading, setLoading] = useState(true);

  const [submit, setSubmit] = useState(false);
  const [option, setOption] = useState(4);
  const [transcriptLanguague, setTranscriptLanguague] = useState(null);

  const dataToolRole = useDataToolRole();

  const [isExporting, setIsExporting] = useState(false);
  const [exportRange, setExportRange] = useState(null);

  const fetched = useRef(false);

  useEffect(() => {
    if (user && !fetched.current) {
      fetched.current = true;
      setLoading(true);
      let query = db.collection("files");
      if (!IS_FUNIX) {
        if (dataToolRole === "annotator") {
          query = query.where("annotator", "==", user?.email);
        } else if (dataToolRole === "reviewer") {
          query = query.where("reviewer", "==", user?.email);
        } else if (dataToolRole === "approver") {
          query = query.where("approver", "==", user?.email);
        } else query = query.where("user", "==", user?.uid);
      } else query = query.where("tenantId", "==", REACT_APP_TENANT_ID);

      query.orderBy("createdAt", "desc").onSnapshot((snapshot) => {
        setLoading(true);
        const files = snapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
            createdAt: data?.createdAt?.toDate(),
            length: convertToDuration(data?.length),
            lastEdit: data?.lastEdit?.toDate() || data?.createdAt?.toDate(),
          };
        });

        setFiles(files);
        setFilterFiles(files);
        setLoading(false);
      });

      db.doc(`users/${user.uid}`)
        .get()
        .then((doc) => {
          if (!doc.exists) {
            return;
          }
        })
        .catch((err) => {});
    }
  }, [user]);

  const convertToDuration = (value) => {
    if (isNaN(value) || !isFinite(value)) return "00:00:00";

    // calculate hours
    const hours = Math.floor(value / 3600);
    value -= hours * 3600;

    // calculate minutes
    const minutes = Math.floor(value / 60) % 60;
    value -= minutes * 60;

    // calculate minutes
    const seconds = Math.round(value);

    return `${hours < 10 ? "0" : ""}${hours}:${
      minutes < 10 ? "0" : ""
    }${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const onSearch = async (e) => {
    if (!e) return;

    const value = e.target ? e.target.value : e;

    const index = searchClient.index("transcripts");
    const result = await index.search(value);
    setFilterFiles((prev) =>
      files.filter((item) =>
        result.hits.some((hit) => item.name.includes(hit.id))
      )
    );
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {language.search}
          </Button>
          <Button
            onClick={() => {
              clearFilters();
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    render: (text, record, index) => {
      if (record.status === "TRANSCRIBED") {
        return (
          <a
            rel="noopener noreferrer"
            target={"_blank"}
            href={baseUrl + "/" + record.id}
          >
            {record.name}
          </a>
        );
      } else {
        return <div>{record.name}</div>;
      }
    },
  });

  const columns = [
    {
      title: language.name,
      dataIndex: "name",
      width: "15%",
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
      },
      ...getColumnSearchProps("name"),
    },
    {
      title: language.length,
      align: "center",
      dataIndex: "length",
      width: "3%",
      sorter: {
        compare: (a, b) => a.length.localeCompare(b.length),
      },
      render: (text, record, index) => {
        if (record.status === "TRANSCRIBED") return <div>{record.length}</div>;
      },
    },
    {
      title: language.uploadDate,
      align: "center",
      dataIndex: "uploadDate",
      width: "5%",
      sorter: {
        compare: (a, b) => a.createdAt - b.createdAt,
      },
      render: (text, record, index) => {
        if (i18n.language === "vi") {
          return (
            <div>
              <div>{`Ngày ${moment(record.createdAt).format(
                "D"
              )} Tháng ${moment(record.createdAt).format("M")}`}</div>
              <div>{`Năm ${moment(record.createdAt).format("YYYY")}`}</div>
            </div>
          );
        } else {
          return <div>{`${moment(record.createdAt).format("LL")}`}</div>;
        }
      },
    },
    {
      title: language.lastEdit,
      align: "center",
      width: "5%",
      sorter: {
        compare: (a, b) => a.lastEdit - b.lastEdit,
      },
      render: (record) => {
        if (i18n.language === "vi") {
          return (
            <div>
              <div>{`Ngày ${moment(record.lastEdit).format("D")} Tháng ${moment(
                record.lastEdit
              ).format("M")}`}</div>
              <div>{`Năm ${moment(record.lastEdit).format("YYYY")}`}</div>
            </div>
          );
        } else {
          return <div>{`${moment(record.lastEdit).format("LL")}`}</div>;
        }
      },
    },
    {
      title: language.status,
      align: "center",
      width: "3%",
      sorter: {
        compare: (a, b) => a.status.localeCompare(b.status),
      },
      render: (record) => {
        if (
          dataToolRole === "annotator" ||
          dataToolRole === "reviewer" ||
          dataToolRole === "approver"
        )
          return <DataToolTag file={record} role={dataToolRole} />;
        switch (record.status) {
          case "TRANSCRIBING":
            return (
              <Tag icon={<SyncOutlined spin />} color="#108ee9">
                {language.transcribing}
              </Tag>
            );
          case "TRANSCRIBED":
            return (
              <a target={"_blank"} href={baseUrl + "/" + record.id}>
                <Tag color="#87d068">{language.ready}</Tag>
              </a>
            );
          case "ERROR":
            return <Tag color="#ff0000">{language.error}</Tag>;
          case "UPLOADED":
            return <Tag color="#ff9300">{language.uploaded}</Tag>;
          case "DOWNLOADING":
            return <Tag color="#ff9300">Downloading</Tag>;
          default:
            return null;
        }
      },
    },
    ...(dataToolRole === "admin"
      ? [
          {
            title: "Annotator",
            width: "3%",
            render: (record) => (
              <div>
                <div style={{ marginBottom: 8 }}>
                  {record.annotator ? record.annotator : "—"}
                </div>
                <div style={{ marginBottom: 8 }}>
                  <DataToolTag file={record} role="annotator" />
                </div>
                <div>
                  {record.annotatorDeadline
                    ? moment(record.annotatorDeadline).format(
                        "DD/MM/YYYY HH:mm"
                      )
                    : "—"}
                </div>
              </div>
            ),
          },
          {
            title: "Reviewer",
            width: "3%",
            render: (record) => (
              <div>
                <div style={{ marginBottom: 8 }}>
                  {record.reviewer ? record.reviewer : "—"}
                </div>
                <div style={{ marginBottom: 8 }}>
                  <DataToolTag file={record} role="reviewer" />
                </div>
                <div>
                  {record.reviewerDeadline
                    ? moment(record.reviewerDeadline).format("DD/MM/YYYY HH:mm")
                    : "—"}
                </div>
              </div>
            ),
          },
          {
            title: "Approver",
            width: "3%",
            render: (record) => (
              <div>
                <div style={{ marginBottom: 8 }}>
                  {record.approver ? record.approver : "—"}
                </div>
                <div style={{ marginBottom: 8 }}>
                  <DataToolTag file={record} role="approver" />
                </div>
                <div>
                  {record.approverDeadline
                    ? moment(record.approverDeadline).format("DD/MM/YYYY HH:mm")
                    : "—"}
                </div>
              </div>
            ),
          },
        ]
      : dataToolRole === "annotator" ||
        dataToolRole === "reviewer" ||
        dataToolRole === "approver"
      ? [
          {
            title: "Deadline",
            width: "3%",
            render: (record) => {
              const deadlineTime =
                dataToolRole === "annotator"
                  ? record.annotatorDeadline
                  : dataToolRole === "reviewer"
                  ? record.reviewerDeadline
                  : record.approverDeadline;
              return deadlineTime
                ? moment(deadlineTime).format("DD/MM/YYYY HH:mm")
                : "None";
            },
          },
        ]
      : []),
    {
      title: language.action,
      align: "center",
      width: "2%",
      render: (record) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            {dataToolRole === "admin" && (
              <Dropdown
                overlay={<AssignForm file={record} />}
                trigger={["click"]}
              >
                <Button type="link">Assigns</Button>
              </Dropdown>
            )}
            {record.status === "TRANSCRIBED" && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={baseUrl + "/" + record.id}
              >
                <Button type="link" icon={<EyeOutlined />}>
                  {language.btnView}
                </Button>
              </a>
            )}

            {dataToolRole !== "annotator" && dataToolRole !== "reviewer" && (
              <Button
                icon={<DeleteOutlined />}
                type="link"
                danger
                onClickCapture={() => {
                  confirm({
                    title: language.doYouWantToDeleteTheseItems,
                    icon: <ExclamationCircleOutlined />,
                    onOk() {
                      if (user) {
                        setLoading(true);
                        if (IS_FUNIX && record.source === "funix") {
                          axios
                            .delete(REACT_APP_FUNIX_API + "/file", {
                              data: {
                                fileName: record.uuid,
                              },
                              headers: {
                                Authorization: `Bearer ${token}`,
                              },
                            })
                            .then((res) => {
                              if (res.status === 200) {
                                message.success(language.deleteSuccess);
                                setLoading(false);
                              } else {
                                message.error(language.deleteFail);
                                setLoading(false);
                              }
                            })
                            .catch((err) => {
                              message.error(language.deleteFail);
                              setLoading(false);
                            });
                        } else {
                          if (IS_FUNIX) {
                            searchClient
                              .index("transcripts")
                              .deleteDocument(
                                record.name.split(".").slice(0, -1).join(".")
                              );
                          }

                          // Delete Items Action
                          storage
                            .refFromURL(record.url)
                            .delete()
                            .catch((err) => {
                              message.error(language.deleteFail);
                              setLoading(false);
                            });
                          if (record.taskId)
                            db.collection("audioTasks")
                              .doc(record.taskId)
                              .update({
                                status: "DELETED",
                              });

                          db.collection("files")
                            .doc(record.id)
                            .delete()
                            .catch((err) => {
                              message.error(language.deleteFail);
                              setLoading(false);
                            });

                          // Delete subcollections
                          db.collection("files")
                            .doc(record.id)
                            .collection("sentences")
                            .get()
                            .then((snapshot) => {
                              snapshot.forEach((doc) => {
                                doc.ref.delete();
                              });
                            });

                          db.collection("files")
                            .doc(record.id)
                            .collection("sentencesEdited")
                            .get()
                            .then((snapshot) => {
                              snapshot.forEach((doc) => {
                                doc.ref.delete();
                              });
                            });

                          message.success(language.deleteSuccess);
                          setLoading(false);
                        }
                      } else {
                        message.error(language.deleteFail);
                        setLoading(false);
                      }
                    },
                    onCancel() {
                      console.log("Cancel");
                    },
                  });
                }}
              >
                {language.btnDelete}
              </Button>
            )}
          </div>
        );
      },
    },
  ];

  const onSelectFile = async (e) => {
    const file = e.file;
    if (file) {
      const fileUUID = uuid.v4();
      const createdAt = moment();

      const fileExtension = file.name.split(".").pop();
      const storageRef = storage.ref();
      const fileRef = storageRef.child(`${fileUUID}.${fileExtension}`);

      const uploadTask = fileRef.put(file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );

          if (e.onProgress) e.onProgress({ percent: percent });
          else setSubmit(true);
        },
        (err) => {
          if (e.onError) e.onError(err);
        },
        () => {
          // download url
          uploadTask.snapshot.ref.getDownloadURL().then(async (url) => {
            if (option === 4) {
              let payload = {
                uuid: fileUUID,
                name: file.name.split(".").slice(0, -1).join("."),
                user: user?.uid,
                length: 0,
                createdAt: createdAt.toDate(),
                lastEdit: createdAt.toDate(),
                url: url,
                status: "UPLOADED",
                tenantId: REACT_APP_TENANT_ID,
                source: "saas",
                useSqs: true,
              };

              if (lang !== "vn") {
                payload.lang = "jp";
              }

              await db.collection("files").doc(fileUUID).set(payload);
            } else {
              const task = await db.collection("audioTasks").add({
                createdAt: createdAt.toDate(),
                url,
                status: "UPLOADED",
                language: transcriptLanguague ?? lang,
                fileName: file.name,
                tenantId: REACT_APP_TENANT_ID,
                ...optionsData[option].data,
              });

              let payload = {
                uuid: fileUUID,
                name: file.name.split(".").slice(0, -1).join("."),
                user: user?.uid,
                length: 0,
                createdAt: createdAt.toDate(),
                lastEdit: createdAt.toDate(),
                taskId: task.id,
                url: url,
                status: "UPLOADED",
                tenantId: REACT_APP_TENANT_ID,
                source: "saas",
              };

              if (IS_FUNIX)
                searchClient.index("transcripts").updateDocuments([
                  {
                    id: payload.name.split(".").slice(0, -1).join("."),
                    transcript: [],
                  },
                ]);

              db.collection("files").add(payload);
            }
          });
          if (e.onSuccess) e.onSuccess();
          else setSubmit(false);
        }
      );
    }
  };

  const props = {
    name: "file",
    customRequest: onSelectFile,
    showUploadList: {
      showPreviewIcon: false,
      showRemoveIcon: false,
    },
    multiple: true,
  };

  const submitLink = async (e) => {
    setSubmit(true);
    if (e.links) {
      if (e.links.some(({ link }) => !isValidUrl(link))) {
        message.error("Some links are invalid. Please check again!");
        setSubmit(false);
        return;
      }
      const createdAt = moment();

      e.links.forEach(async (item, index) => {
        const url = item.link;
        const fileUUID = uuid.v4();

        const taskMetadata = {
          createdAt: createdAt.toDate(),
          url,
          status: "UPLOADED",
          language: transcriptLanguague ?? lang,
          fileName: url,
          tenantId: REACT_APP_TENANT_ID,
          ...optionsData[option].data,
        };

        let payload = {
          uuid: fileUUID,
          taskMetadata,
          name: item.link,
          user: user?.uid,
          length: 0,
          createdAt: new Date(),
          lastEdit: new Date(),
          url,
          status: "DOWNLOADING",
          tenantId: REACT_APP_TENANT_ID,
          source: "saas",
        };

        db.collection("files").add(payload);
      });
      message.success("We're downloading your files. Please wait a moment!");
    }
    setSubmit(false);
  };

  const handleExportData = async () => {
    setIsExporting(true);
    try {
      let fileQuery = db.collection("files").where("user", "==", user?.uid);

      if (exportRange) {
        fileQuery = fileQuery
          .where("createdAt", ">=", exportRange[0].toDate())
          .where("createdAt", "<=", exportRange[1].toDate());
      }

      const data = await fileQuery.get();
      const files = data.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

      // For export json data
      const allExportData = [];

      for (const fileChunk of chunk(files, 5)) {
        await Promise.all(
          fileChunk.map(async (file) => {
            const id = file.id;
            const fileRef = db.collection("files").doc(id);

            const rawSentencesDocs = await fileRef
              .collection("sentences")
              .get();

            // Get raw sentences data
            const rawSentences = rawSentencesDocs.docs.map((doc) => doc.data());

            // Add raw sentences to all Data
            allExportData.push({
              fileName: file.name + "_raw",
              jsonData: {
                email: "none",
                id: file.id,
                data: rawSentences,
              },
            });

            // Get edited sentences data
            const editedSentencesDocs = await fileRef
              .collection("edited")
              .get();

            editedSentencesDocs.forEach((doc) => {
              const sentences = doc.data().sentences;

              let email = "";
              let phase = doc.id;

              switch (doc.id) {
                case "annotator":
                  email = file.annotator;
                  break;
                case "reviewer":
                  email = file.reviewer;
                  break;
                case "user":
                  email = file.approver;
                  phase = "approver";
                  break;
                default:
              }

              allExportData.push({
                fileName: file.name + "_" + phase,
                jsonData: {
                  email,
                  id: file.id,
                  data: sentences,
                },
              });
            });
          })
        );
      }

      const zip = new JSZip();

      for (const exportData of allExportData) {
        const { fileName, jsonData } = exportData;

        const blob = new Blob([JSON.stringify(jsonData, null, 4)], {
          type: "application/json",
        });

        zip.file(fileName + ".json", blob);
      }
      zip.file(
        "audio_list.txt",
        files
          .map(
            (file) => `${file.name}.${getUrlExtension(file.url)}\t${file.url}`
          )
          .join("\n")
      );

      const content = await zip.generateAsync({ type: "blob" });

      saveAs(content, "export.zip");
    } catch (e) {
      console.log(e);
    } finally {
      setIsExporting(false);
    }
  };

  const menu = (
    <Menu>
      {optionsData.map((item, index) => (
        <Menu.Item key={index}>
          <div
            onClick={(e) => {
              e.preventDefault();
              setOption(index);
            }}
          >
            {t(item.label)}
          </div>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Layout className="jobs-page">
      <Layout style={{ paddingLeft: 24, paddingRight: 24, marginTop: 20 }}>
        {(!dataToolRole ||
          dataToolRole === "admin" ||
          dataToolRole === "tool") && (
          <Card style={{ marginBottom: 18 }}>
            {`${language.fileTranscriptSettings} `}
            <Dropdown overlay={menu}>
              <Space>
                <span style={{ color: "#4a90e2", fontWeight: "bold" }}>
                  {t(optionsData[option].label)}
                </span>
                <DownOutlined />
              </Space>
            </Dropdown>
            {dataToolRole === "tool" && (
              <Row style={{ marginTop: 8 }}>
                <Space>
                  Transcript language:
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item>
                          <div
                            onClick={(e) => {
                              e.preventDefault();
                              setTranscriptLanguague("vn");
                            }}
                          >
                            Vietnamese
                          </div>
                        </Menu.Item>
                        <Menu.Item>
                          <div
                            onClick={(e) => {
                              e.preventDefault();
                              setTranscriptLanguague("jp");
                            }}
                          >
                            Japanese
                          </div>
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <Space>
                      <span style={{ color: "#4a90e2", fontWeight: "bold" }}>
                        {transcriptLanguague
                          ? transcriptLanguague === "vn"
                            ? "Vietnamese"
                            : "Japanese"
                          : "Select language"}
                      </span>
                      <DownOutlined />
                    </Space>
                  </Dropdown>
                </Space>
              </Row>
            )}
            <Row gutter={24} style={{ marginTop: 24 }}>
              <Col span={12}>
                <Card style={{ height: `100%` }}>
                  <Dragger {...props}>
                    <p className="ant-upload-drag-icon">
                      <DownloadOutlined
                        style={{ fontSize: 36, color: "inherit" }}
                      />
                    </p>
                    <p className="ant-upload-text">
                      {language.selectDragDropVideo}
                    </p>
                  </Dragger>
                </Card>
              </Col>
              <Col span={12}>
                <Card style={{ height: `100%`, justifyContent: "center" }}>
                  <p style={{ fontSize: 16 }}>{language.pasteVideo}</p>
                  <Form
                    onFinish={submitLink}
                    initialValues={{
                      links: [
                        {
                          link: "http://link.com/video.mp4",
                        },
                      ],
                    }}
                  >
                    <Form.List name="links">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, ...restField }, index) => (
                            <>
                              <Row gutter={24}>
                                <Col lg={{ span: 20 }} xs={{ span: 20 }}>
                                  <Form.Item
                                    rules={[
                                      {
                                        required: true,
                                        message: "This field is required",
                                      },
                                      {
                                        type: "url",
                                        message: "Please enter a valid url",
                                      },
                                    ]}
                                    label={"Link"}
                                    {...restField}
                                    name={[name, "link"]}
                                  >
                                    <Input />
                                  </Form.Item>
                                </Col>
                                <Col lg={{ span: 4 }} xs={{ span: 4 }}>
                                  {fields.length !== 1 && (
                                    <>
                                      <MinusCircleOutlined
                                        onClick={() => remove(name)}
                                      />{" "}
                                      {language.btnDelete}
                                    </>
                                  )}
                                </Col>
                              </Row>
                            </>
                          ))}
                          <Button type="primary" htmlType="submit">
                            {language.btnSubmit}
                          </Button>
                          <Button
                            loading={submit}
                            type="link"
                            onClick={() => add()}
                            icon={<PlusOutlined />}
                          >
                            {language.btnAdd}
                          </Button>
                        </>
                      )}
                    </Form.List>
                  </Form>
                </Card>
              </Col>
            </Row>
          </Card>
        )}
        {IS_FUNIX && (
          <div style={{ width: "100%" }}>
            <Search
              placeholder={language.search}
              onSearch={onSearch}
              onChange={onSearch}
              style={{ width: 300, marginBottom: 18, float: "right" }}
            />
          </div>
        )}
        {dataToolRole === "admin" && (
          <Card style={{ marginBottom: 18 }}>
            <DatePicker.RangePicker
              onChange={(values) => setExportRange(values)}
            ></DatePicker.RangePicker>
            <Button
              loading={isExporting}
              type="primary"
              onClick={handleExportData}
            >
              Export
            </Button>
          </Card>
        )}
        <Table
          rowKey={"id"}
          loading={loading}
          bordered
          columns={columns}
          dataSource={filterFiles}
          pagination={{
            defaultPageSize: 20,
            showSizeChanger: true,
          }}
        />
      </Layout>
    </Layout>
  );
};

export default SaasTranscripts;
