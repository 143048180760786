export default {
  login: "ログイン",
  logout: "ログアウト",
  password: "パスワード",
  forgotPassword: "パスワードをお忘れですか ?",
  changePassword: "パスワードを変更する",
  changePasswordSuccessTitle: "パスワードを正常に変更する",
  changePasswordSuccessContent: "パスワードが正常に変更されました。",
  currentPassword: "現在のパスワード",
  pleaseInputCurrentPassword: "現在のパスワードを入力してください",
  newPassword: "新しいパスワード",
  pleaseInputNewPassword: "新しいパスワードを入力してください。",
  passwordErrorMinimum: "パスワードは8文字以上である必要があります",
  passwordErrorUppercase: "パスワードには少なくとも1つの大文字が必要です",
  passwordErrorLowercase: "パスワードには少なくとも1つの小文字が必要です",
  passwordErrorNumber: "パスワードには少なくとも1つの番号が必要です",
  slogan: "自動音声認識",
  companyRights: "© 2022 NAMI TECHNOLOGY JSC, Inc. 全著作権所有.",
  dictionary: "辞書",
  dictionarySuccess: "辞書は正常に保存されました",
  word1: "不正な出力",
  word2: "Incorrect yomigana",
  word3: "正しい出力",
  word4: "Correct yomigana",
  word5: "タイプ",
  transcripts: "トランスクリプト",
  transcript: "トランスクリプト",
  record: "記録",
  correctedTranscript: "修正されたトランスクリプト",
  createdAt: "作成場所",
  audio: "オーディオ",
  secLimit: "600秒の制限",
  seconds: "秒",
  predicting: "予測...",
  selectDragDropAudio:
    "オーディオファイルを選択またはドラッグアンドドロップします",
  bugSuccess: "バグは正常に作成されました",
  selectLanguage: "言語を選択する",
  japanese: "日本語",
  vietnamese: "ベトナム語",
  haveBugs: "バグがある",
  noMessage: "メッセージはありません",
  placeholderPassword: "パスワード",
  loading: "読み込み中",
  user: "ユーザー",
  statistics: "統計学",
  admin: "管理者",
  doYouWantToDeleteTheseItems: "これらのアイテムを削除しますか？",
  doYouWantToCancel: "キャンセルしますか？",
  btnSubmit: "提出",
  btnStop: "止まる",
  requiredPassword: "パスワードが必要です",
  btnCancel: "キャンセル",
  btnUpdate: "アップデート",
  btnAdd: "追加",
  btnEdit: "編集",
  btnDelete: "消去",
  btnSave: "保存",
  btnRecord: "記録",
  requiredEmail: "Eメールが必要です",
  email: "Eメール",
  emailRequirePlaceholder: "Eメールアドレスを入力してください",
  recordAudio: "レコードオーディオ",
  name: "名前",
  phone: "電話",
  active: "アクティブ",
  inactive: "非活性",
  word1Required: "不正な出力が必要です。",
  word2Required: "不正な読み仮名が必要です。",
  word3Required: "正しい出力が必要です。",
  word4Required: "正しい読み仮名が必要です。",
  word5Required: "タイプが必要です。",
  connectionStatus: "接続ステータス",
  closed: "閉鎖",
  connecting: "接続中",
  connected: "接続",
  live: "ライブ",
  offline: "オフライン",
  btnStart: "スタート",
  successSave: "正常に保存",
  btnDownload: "ダウンロード",
  btnUpload: "アップロード",
  line: "ライン",
  uploadFail: "アップロードに失敗しました!",
  uploadSuccess: "行が正常にアップロードされました!",
  meetings: "ミーティング",
  btnView: "見る",
  actions: "行動",
  meeting: "ミーティング",
  export: "エクスポート",
  exportAll: "すべてエクスポート",
  exportSelected: "選択したものをエクスポートする",
  revert: "元に戻す",
  play: "プレイ",
  pause: "中断",
  jumpTo: "ジャンプ",
  jumpToTimestamp: "ジャンプタイムスタンプ",
  speed: "スピード",
  volume: "ボリューム",
  setVolume: "ボリュームをセットする",
  notes: "ノート",
  writeNotesHere: "ここにノートを書く",
  participants: "参加者",
  length: "長さ",
  date: "日時",
  ID: "ID",
  lastEdit: "最終編集日",
  sortBy: "順序",
  sortByRequired: "順序が必要です。",
  inProgress: "進行中...",
  filter: "フィルター",
  showAll: "すべて表示",
  showOnlySelected: "選択したのみ表示",
  dev: "開発者",
  transcriptionStarted: "文字起こしが開始されました",
  transcriptionStopped: "文字起こしが停止しました",
  replacedDict: "辞書が置き換えられました",
  hour: "時間",
  minute: "分",
  minutes: "分",
  second: "秒",
  apply: "適用",
  jumpToNewTimestampSuccess: "新しいタイムスタンプにジャンプしました",
  jumpToEndSuccess: "終了にジャンプしました",
  saveSuccess: "正常に保存",
  saveFailed: "保存に失敗しました",
  backToLogin: "ログインに戻る",
  meetingClose: "ミーティングを退出しました",
  wrongEmailPassword: "メールまたはパスワードが間違っています",
  wrongEmailFormat: "メールの形式が間違っています",
  userNotFound: "ユーザーが見つかりません",
  resetPasswordSuccess: "パスワードが正常にリセットされました",
  deleteSuccess: "正常に削除されました",
  deleteFail: "削除に失敗しました",
  uploaded: "アップロードされました",
  totalWords: "総単語数",
  fileTranscriptSettings:
    "話し手の数とオーディオ/ビデオ ファイルの設定を入力してください:",
  onePerson: "1人",
  twoPeopleMonoChannel: "2人(モノラルチャンネルファイル)",
  twoPeopleStereoChannels: "2人(ステレオチャンネルファイル)",
  twoPeopleMonoChannelWithoutOverlap: "2人(話が重ならないモノラルチャンネル)",
  twoPeopleOrMore: "2 people or more",
  pasteVideo: "或いはビデオリンクをここに貼り付けください",
  selectDragDropVideo:
    "オーディオ/ビデオファイルを選択またはドラッグアンドドロップします",
  uploadDate: "アップロード日付",
  status: "ステータス",
  action: "アクション",
  ready: "準備完了",
  transcribing: "文字起こし中",
  transcribed: "文字起こし済み",
  uploading: "アップロード中",
  success: "成功",
  error: "エラー",
  totalLines: "合計：{{lines}}行",
  daily: "毎日",
  monthly: "毎月",
  today: "本日",
  yesterday: "昨日",
  thisWeek: "今週",
  thisMonth: "今月",
  thisQuarter: "今四半期",
  thisYear: "今年",
  speaker: "スピーカー",
  total: "合計",
  search: "検索",
  person: "人",
  people: "人",
};
